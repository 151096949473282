import { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';

import fetchContentfulData from 'api/contentful-fetch';
import { ExtendedNextPageContext, ServerSidePropsOrRedirect, UnknownObjectAny } from 'global.types';
import { PageCMSData } from 'types/cms';
import { pageQuery } from 'graphql/queries/page';
import { Banner, ComponentGenerator, Hero } from 'components/CMS';
import { graphQLErrorHandler } from 'utils/graphQLHandlers';
import CenterContent from 'components/containers/CenterContent';

export interface CMSPageProps {
  content: PageCMSData;
}

const CMSPage = ({ content }: CMSPageProps): ReactElement => {
  return (
    <>
      {content?.pageAlertBanner && <Banner isPageAlertBanner {...content?.pageAlertBanner} />}
      <main>
        <Hero data={content} />
        <Box>
          <CenterContent>
            {content?.bodyCollection?.items?.map(({ __typename, ...rest }, index) => (
              <ComponentGenerator key={`component-${index}`} __typename={__typename} {...rest} />
            ))}
          </CenterContent>
        </Box>
      </main>
    </>
  );
};

export async function getServerSideProps({
  req,
  query,
  preview = false
}: ExtendedNextPageContext): ServerSidePropsOrRedirect {
  let slug = req?.url?.substring(req?.url?.lastIndexOf('/') + 1)?.replace('.json', '');
  if (typeof query?.pageSlug === 'string') {
    slug = query.pageSlug;
  }
  const content = (await fetchContentfulData(
    {
      query: pageQuery,
      variables: {
        slug,
        preview
      }
    },
    preview
  )) as UnknownObjectAny;

  graphQLErrorHandler('pageQuery', content?.errors, pageQuery);

  if (!content?.data?.pageCollection?.items?.length) {
    return {
      notFound: true
    };
  }
  return { props: { content: content?.data?.pageCollection?.items?.[0] } };
}

export default CMSPage;
